//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadBoxScore } from '../../Actions/BoxScoreActions';
import BoxScoreJSON from '../../Data/StatSheet.json';

type Props = {
  dispatch: any => void,
  box_score: Object,
  window_width: number,
  onRequestClose: () => void
}
type State = {}

const mapStateToProps = (state: Object) => {

    return {
        box_score: state.box_score.data,
        window_width: state.window.width
    };
};

class BoxScore extends Component<Props, State> {

  renderRow: (data: Object, index: any, style: ?Object) => Object
  onRequestClose: () => void

  constructor(props: Object) {
      super(props);
      this.props.dispatch(loadBoxScore(BoxScoreJSON));
      this.renderRow = this.renderRow.bind(this);
      this.onRequestClose = this.onRequestClose.bind(this);
  }

  render() {
      return (
          <div style={{ margin: 40.0, justifyContent: "center", display: "flex", flexDirection: "column" }}>
              {this.renderExitButton()}
              {this.renderBoxScore()}
          </div>
      );
  }

  onRequestClose() {
      if (this.props.onRequestClose) {
          this.props.onRequestClose();
      }
  }

  renderExitButton() {
      return (
          <div onClick={this.onRequestClose}>
              <h1 style={{ color: "white", marginTop: 60 }}>{"X"}</h1>
          </div>
      );
  }

  renderBoxScore() {
      return (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              {this.renderHeader()}
              {Object.values(this.props.box_score).map((rowData, index) => {
                  return this.renderRow(rowData, index);
              })}
          </div>
      );
  }

  renderHeader() {
      const headerData: Object = {
          "NO": "NO",
          "NAME": "NAME",
          "PTS": "PTS",
          "REBS": "REBS",
          "AST": "AST",
          "STL": "STL",
          "BLK": "BLK",
          "TO": "TO",
          "FG": "FG",
          "FT": "FT",
          "3PT": "3PT"
      };
      const style: Object = {
          backgroundColor: "black",
          display: "flex",
          flexDirection: "row",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flex: 1, maxHeight: 30,
      };
      return this.renderRow(headerData, "headerData", style);
  }

  renderRow(data: Object, index: any, style: ?Object) {
      // modify data for small orientations
      var rowStyle = style || { flex: 1, maxHeight: 30, display: "flex", flexDirection: "row", backgroundColor: "white", justifyContent: "center", alignItems: "center" };
      const rowSize: number = this.props.window_width > 500 ? data.length : 5;
      const statsData = Object.values(data).slice(0, rowSize);
      return (
          <div key={index} style={rowStyle}>
              {Object.values(statsData).map((val, index) => {
                  return (
                      <div key={index} style={rowStyle}>
                          <p style={{ fontSize: 10, marginTop: 10 }} key={index}>{val}</p>
                      </div>
                  );
              })}
          </div>
      );
  }
}

export default connect(mapStateToProps)(BoxScore);