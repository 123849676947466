//@flow
import React, { Component } from "react";
import Team from '../../Types/Team';
import { type Division, MOCK_STANDINGS } from '../../Mock/StandigsAPI';
import { isMobile } from "react-device-detect";
import Placeholder from '../../Lib/Placeholder';

type State = {}
type Props = {}

class Standings extends Component<Props, State> {

    render() {
        return (
            <div>
                <h1 style={{color: "white"}}>{"2018 Standings"}</h1>
                {MOCK_STANDINGS.divisions.map((division, index) => {
                    return (
                        <div>
                            {this.renderDivisionHeader(division, index)}
                            {this.renderStatsHeader()}
                            {division.teams.map((team) => {
                                return this.renderTeamRow(team);
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderPlacholder() {
        const text: string = "League Standings Will Be Updated Throughout the Season";
        return (
            <Placeholder icon={"format_list_numbered"} text={text}/>
        );
    }

    renderStatsHeader() {
        const section_size: number = isMobile ? 50 : 100.0;
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", height: 30, paddingTop: 5, borderBottom: "0.5px solid grey" , backgroundColor: "#f0f0f0"}} key={"standings_header"}>
                <h1 style={{ marginLeft: 5,lineHeight: 16, fontWeight: 800, textAlign: "left", color: "black", fontSize: 16, width: 150 }}>{""}</h1>
                <h1 style={{ marginLeft: 5,lineHeight: 16, fontWeight: 800, color: "black", fontSize: 16, width: section_size }}>{"W"}</h1>
                <h1 style={{ marginLeft: 5,lineHeight: 16, fontWeight: 800, color: "black", fontSize: 16, width: section_size }}>{"L"}</h1>
                <h1 style={{ marginLeft: 5,lineHeight: 16, fontWeight: 800, color: "black", fontSize: 16, width: section_size }}>{"+/-"}</h1>
                <h1 style={{ marginLeft: 5,lineHeight: 16, fontWeight: 800, color: "black", fontSize: 16, width: section_size }}>{"DIV"}</h1>
            </div>
        );
    }

    renderDivisionHeader(division: Division, index: number) {
        return (
            <div key={index} style={{borderBottom: "0.5px solid grey", flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#f0f0f0", alignItems: "center", height: 30, paddingTop: 5 }}>
                <h1 style={{ marginLeft: 15, lineHeight: 16, fontWeight: 800, textAlign: "left", color: "black", fontSize: 16, width: 200 }}>{division.name}</h1>
            </div>
        );
    }

    renderTeamRow(team: Team) {
        const section_size: number = isMobile ? 50 : 100.0;
        const font_size: number = isMobile ? 14 : 16;
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#a0a0a0" }} key={team.key}>
                <h1 style={{ marginLeft: 5, textAlign: "left", color: "black", fontSize: 16, width: 150 }}>{team.team_name}</h1>
                <h1 style={{ marginLeft: 5, color: "black", fontSize: font_size, width: section_size }}>{team.wins}</h1>
                <h1 style={{ marginLeft: 5, color: "black", fontSize: font_size, width: section_size }}>{team.losses}</h1>
                <h1 style={{ marginLeft: 5, color: "black", fontSize: font_size, width: section_size }}>{team.plus_minus}</h1>
                <h1 style={{ marginLeft: 5, color: "black", fontSize: font_size, width: section_size }}>{team.dvision_record}</h1>
            </div>
        );
    }

}

export default Standings;