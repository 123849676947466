//@flow
import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import Theme from '../../src/Config/Theme';

type Props = {
  icon: string,
  text: string,
}

type State = {}

export default class Placeholder extends Component<Props, State> {

    render() {
        return (
            <div style={{ ...this.props.style, flex: 1, height: "100%", cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                <h1 style={{color: Theme.primaryText}}>{this.props.text}</h1>
                <MaterialIcon color={Theme.orange} icon={this.props.icon} size={100} />
            </div>
        );
    }

}