//@flow
import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';
import { FaBasketballBall } from 'react-icons/fa';
import { TiLocationArrow } from 'react-icons/ti';
import { FaBuilding } from 'react-icons/fa';
import Theme from '../../Config/Theme';
import { isMobile } from "react-device-detect";
import MapConfig from '../../Config/MapConfig';
import Image from '../../Lib/Image';
import './PageStyles.css';

type Props = {}
type State = {}

export default class Home extends Component<Props, State> {

    renderMap() {
        return (
            <div style={{ width: "80%", height: 400, alignItems: "center", justifyContent: "center" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: MapConfig.API_KEY }}
                    defaultCenter={{
                        lat: MapConfig.latitude,
                        lng: MapConfig.longitude,
                    }}
                    defaultZoom={14.0}
                >
                    <FaBuilding
                        style={{ height: 50, width: 50, color: Theme.black }}
                        lat={MapConfig.latitude}
                        lng={MapConfig.longitude}
                    />
                </GoogleMapReact>
            </div>
        );
    }

    renderMission() {
        const fontSize: number = isMobile ? 20 : 24;
        return (
            <div style={{ marginBottom: 50 }}>
                <FaBasketballBall style={{ height: 50, width: 50, color: "#A5CAEB" }} />
                <h1 style={{marginBottom: 20, color: Theme.primaryText}}>{"MISSION"}</h1>
                <p style={{ color: Theme.primaryText, fontSize: fontSize, fontWeight: "300" }}>{"The Brooks Rec League is dedicated to displaying basketball talent and competition through integrity, accountability, commitment, and sportsmanship"}</p>
            </div>
        );
    }

    renderLocation() {
        const fontSize: number = isMobile ? 20 : 24;
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <TiLocationArrow style={{ height: 50, width: 50, color: "#A5CAEB" }} />
                <h1 style={{marginBottom: 20, color: Theme.primaryText}}>{"LOCATION"}</h1>
                <p style={{ fontSize: fontSize, marginBottom: 20, color: Theme.primaryText }}>{"We're located at MSA Fieldhouse on 28th Street"}</p>
                <p style={{ color: Theme.primaryText, fontWeight: "600", fontSize: 18 }}>{"5435 28th St Ct SE, Grand Rapids, MI 49546"}</p>
                {this.renderMap()}
            </ div>
        );
    }

    renderGallery() {
        return (
            <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", marginBottom: 50}}>
                <Image margin={10} height={300} width={300} src={'./assets/Photography/photo_0.jpg'}/>
                <Image margin={10} height={300} width={300} src={'./assets/Photography/photo_1.jpg'}/>
                <Image margin={10} height={300} width={300} src={'./assets/Photography/photo_5.jpg'}/>
                <Image margin={10} height={300} width={300} src={'./assets/Photography/photo_2.jpg'}/>
            </div>
        );
    }

    render() {
        return (
            <div style={{paddingRight: 20.0, paddingLeft: 20.0, paddingBottom: 100, flexDirection: "column" }} className="Home">
                {this.renderGallery()}
                {this.renderMission()}
                {this.renderLocation()}
            </div>
        );
    }
}



