//@flow
import React, { Component } from 'react';

type Props = {
  src: string,
  height: number,
  width: number,
  onClick: void => void
}
type State = {
  hovering: boolean,
}

export default class Image extends Component<Props, State> {

    onMouseLeave: () => void
    onMouseEnter: () => void

    constructor(props: Object) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.renderOverlay = this.renderOverlay.bind(this);
        this.state = {
            hovering: false
        };
    }

    onMouseEnter() {
        this.setState({
            hovering: true,
        });
    }

    onMouseLeave() {
        this.setState({
            hovering: false,
        });
    }

    renderOverlay() {
        if(!this.state.hovering) {
            return null;
        }
        return (
            <div style={{position: "absolute", height: this.props.height, width: this.props.width, backgroundColor: "rgba(0,0,0,0.5)"}}/>
        );
    }

    render() {
        return (
            <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.props.onClick} style={{cursor: "pointer", backgroundColor: "black", border: "1px solid white", display: "flex", justifyContent: "flex-start", alignItems: "center", height: this.props.height, width: this.props.width, overflow: "hidden", margin: this.props.margin}}>
                <img src={this.props.src} alt={this.props.alt} style={{width: this.props.width, height: this.props.height, objectFit: "cover", objectPosition: "50% 0"}}/>
                {this.renderOverlay()}
            </div>
        );
    }

}