//@flow
import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import Button from '@material-ui/core/Button';
import Theme from '../../Config/Theme';

type State = {}
type Props = {}

class Registration extends Component<Props, State> {

    render() {
        const fontSize: number = isMobile ? 16 : 18;
        return (
            <div>
                <h1 style={{color: Theme.primaryText}}>{"Registration"}</h1>
                <h4 style={{ fontSize: fontSize, color: Theme.primaryText, fontWeight: "300", marginBottom: 60 }}>{"Download team registration forms below for the upcoming session"}</h4>
                <Button style={{ backgroundColor: Theme.secondary}}><a style={{ color: Theme.primary, fontWeight: "800" }} href="assets/Registration/team_form.pdf" download>{"Download"}</a></Button>
            </div>
        );
    }
}

export default Registration;