//@flow
import { type WindowStateType, UPDATE_WINDOW_STATE } from '../Actions/WindowActions';
import { isMobile } from "react-device-detect";
import { SHOW_BOX_SCORE_MODAL } from '../Actions/WindowActions';

const MINIMUM_DESKTOP_WIDTH: number = 1350;

function resolveISMobile(state: Object) {
    if (!isMobile && (state.width < MINIMUM_DESKTOP_WIDTH)) {
        return true;
    }
    return isMobile;
}

const InitialState: WindowStateType = {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: true,
    box_score_visible: false
};

export default function window(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case UPDATE_WINDOW_STATE:
        const wState: Object = action.state;
        return { width: wState.width, height: wState.height, isMobile: resolveISMobile(wState) };
    case SHOW_BOX_SCORE_MODAL:
        return { box_score_visible: action.visible };
    default:
        return state;
    }
}

