//@flow
import { type WindowDimensionsType } from '../../src/Actions/WindowActions';

type WindowStateHandler = (state: WindowDimensionsType) => void

export function addWindowStateListener(handler: WindowStateHandler) {
    window.addEventListener('resize', () => {
        const height: number = window.innerHeight;
        const width: number = window.innerWidth;
        handler({ height, width });
    });

}

export function removeWindowStateListener(handler: WindowStateHandler) {
    window.removeEventListener('resize', handler);
}