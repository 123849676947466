//@flow
import Team from './Team';

export class GameScore {

  team_1: Team
  team_2: Team
  score_1: number
  score_2: number
  date: Date

  constructor(json: Object) {
      this.team_1 = new Team({ team_name: json.team_1 });
      this.team_2 = new Team({ team_name: json.team_2 });
      this.score_1 = json.score_1 || 0;
      this.score_2 = json.score_2 || 0;
      this.date = new Date(json.date) || new Date();
  }

  log() {
      console.log("GameData", this);
  }
}

export class ScoresData {

  game_data: Array<GameScore>
  map: Object

  constructor(json: Object) {
      this.map = {};
      this.game_data = Object.values(json).map(data => {
          const game: GameScore = new GameScore(data);
          const date: Date = game.date;
          const hash: string = date.toDateString();
          if (!this.map[hash]) {
              this.map[hash] = [];
          }
          this.map[hash].push(game);
          return game;
      });
  }

  log() {
      this.game_data.forEach((gameScore) => {
          gameScore.log();
      });
      console.log("[DATA]", this);
  }
}
