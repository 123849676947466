//@flow
import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import { FaEnvelope } from 'react-icons/fa';
import Theme from '../../Config/Theme';
import ContactInfo from '../../Config/ContactInfo';
import './PageStyles.css';

type Props = {}
type State = {}

export default class Contact extends Component<Props, State> {

    renderHeader() {
        return (
            <div>
                <FaEnvelope style={{ height: 50, width: 50, color: "#276D9E" }} />
                <h1 style={{ color: "black", fontWeight: "800", fontFamily: "IBM Plex Sans" }}>{"CONTACT"}</h1>
            </div>
        );
    }

    renderContactInfo() {
        const fontSize: number = isMobile ? 16 : 18;
        return (
            <div>
                <div style={{ marginBottom: 40 }}>
                    <p style={{ fontSize: fontSize, color: "#A5CAEB", fontWeight: "800" }}>{"PHONE"}</p>
                    <p style={{ fontSize: fontSize, color: Theme.primaryText }}>{ContactInfo.phone}</p>
                </div>
                <div>
                    <p style={{ fontSize: fontSize, color: "#A5CAEB", fontWeight: "800" }}>{"EMAIL"}</p>
                    <p style={{ fontSize: fontSize, color: Theme.primaryText }}>{ContactInfo.email.toLocaleUpperCase()}</p>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }} className="Home">
                {this.renderContactInfo()}
            </div>
        );
    }
}
