//@flow
import firebase from 'firebase/app';
import 'firebase/storage';

const FirebaseConfig: Object = {
  apiKey: "AIzaSyBWr1mFbvosgICzdeBPiw0lej58ZOskn6Y",
  authDomain: "scorekeeper-224001.firebaseapp.com",
  databaseURL: "https://scorekeeper-224001.firebaseio.com",
  projectId: "scorekeeper-224001",
  storageBucket: "scorekeeper-224001.appspot.com",
  messagingSenderId: "643887803936",
  appId: "1:643887803936:web:02f7846748b1cdff"
}

firebase.initializeApp(FirebaseConfig)
export const Storage = firebase.storage()