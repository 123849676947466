//@flow
class Team {

  team_name: string
  wins: number
  losses: number
  key: string
  plus_minus: string
  dvision_record: string

  constructor(json: Object) {
      this.team_name = json.team_name || "";
      this.wins = json.wins || 0;
      this.losses = json.losses || 0;
      this.key = json.team_name || "";
      this.plus_minus = json.plus_minus || "+0";
      this.dvision_record = json.dvision_record || "0-0";
  }
}

export default Team;