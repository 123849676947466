//@flow
import React, { Component } from "react";
import { connect } from 'react-redux';
import { loadSponsors } from '../../Actions/SponsorActions';
import SponsorsJSON from '../../Data/Sponsors.json';

type Props = {}

type State = {}

const mapStateToProps = (state: Object) => {
    return {
        sponsors: state.sponsors.data || {}
    };
};

class Sponsors extends Component<Props, State> {

    componentDidMount() {
        this.props.dispatch(loadSponsors(SponsorsJSON));
    }

    renderSponsor(sponsor: Object) {
        return (
            <img style={{height: 150, width: 150}} src={sponsor.asset} alt={'media'}/>
        ); 
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: this.props.isMobile ? "column" : "row", flex: 1, height: "100%" }}>
                {Object.values(this.props.sponsors).map((sponsor) => {
                    return this.renderSponsor(sponsor);
                })}
            </div >
        );
    }
}

export default connect(mapStateToProps)(Sponsors);