//@flow
import React, { Component } from 'react';
import Theme from '../../Config/Theme';
import { ScoresData, GameScore } from '../../Types/ScoresData';
import ScoresJSON from '../../Data/Scores.json';
import { connect } from 'react-redux';
import { loadScores } from '../../Actions/ScoreActions';
import Modal from 'react-modal';
import { setBoxScoreModalVisibility } from '../../Actions/WindowActions';
import BoxScore from './BoxScore';
import Placeholder from '../../Lib/Placeholder';

type Props = {
  dispatch: any => Object,
  data: ScoresData,
  window_width: number,
  box_score_visible: Boolean,
}

type State = {}

const mapStateToProps = (state: Object) => {
    return {
        data: state.scores.data,
        window_width: state.window.width,
        box_score_visible: state.window.box_score_visible
    };
};

class Scores extends Component<Props, State> {

  renderSingleGameScore: (score: GameScore, index: number) => Object
  renderSingleDayScores: (scores: Array<GameScore>, index: number) => Object

  constructor(props: Object) {
      super(props);
      this.renderSingleDayScores = this.renderSingleDayScores.bind(this);
      this.renderSingleGameScore = this.renderSingleGameScore.bind(this);
  }

  componentDidMount() {
      this.props.dispatch(loadScores(ScoresJSON));
  }


  renderSingleDayScores(scores: Array<GameScore>, index: number) {
      var date: string = "";
      if (scores[0]) {
          date = scores[0].date.toDateString().toUpperCase();
      }
      const flexDirection: string = (window.innerWidth > 500) ? "row" : "column";
      const alignItems: string = (window.innerWidth > 500) ? "flex-start" : "center";
      return (
          <div key={index} style={{ display: "flex", flexDirection: "column" }}>
              <h3 style={{ fontSize: 15, padding: 10, backgroundColor: Theme.dark_grey, color: "white" }}>{date}</h3>
              <div key={index} style={{ display: "flex", flexDirection: flexDirection, justifyContent: alignItems, alignItems: alignItems }}>
                  {scores.map((score, index) => {
                      return this.renderSingleGameScore(score, index);
                  })}
              </div>
          </div>
      );
  }

  renderSingleGameScore(gameScore: GameScore, index: number) {
      const sectionStyle: Object = {
          display: "flex",
          flexDirection: "row",
          width: 150,
          backgroundColor: "lightGrey",
          padding: 5,
          paddingLeft: 20,
          paddingTop: 15,
      };
      const teamTextStyle: string = {
          width: 150,
          textAlign: "left",
      };
      const scoreTextStyle: string = {
          width: 40,
          fontWeight: "800",
      };
      return (
          <div onClick={() => {
              this.props.dispatch(setBoxScoreModalVisibility(true));
          }} style={{ margin: 10, marginLeft: 20, marginBottom: 20 }} key={index}>
              <div style={sectionStyle}>
                  <h6 style={teamTextStyle}>{gameScore.team_1.team_name.toUpperCase()}</h6>
                  <h6 style={scoreTextStyle}>{gameScore.score_1}</h6>
              </div>
              <div style={sectionStyle}>
                  <h6 style={teamTextStyle}>{gameScore.team_2.team_name.toUpperCase()}</h6>
                  <h6 style={scoreTextStyle}>{gameScore.score_2}</h6>
              </div>
          </div>
      );
  }

  renderScores() {
      return (
          <div style={{ flexDirection: "row" }}>
              {Object.values(this.props.data.map).map((data, index) => {
                  return this.renderSingleDayScores(data, index);
              })}
          </div>
      );
  }

  renderBoxScore() {
      const boxStyle = {
          content: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
          }
      };
      return (
          <Modal style={boxStyle} isOpen={this.props.box_score_visible}>
              <div style={{ width: "100%", backgroundColor: Theme.dark_grey }}>
                  <BoxScore onRequestClose={() => {
                      this.props.dispatch(setBoxScoreModalVisibility(false));
                  }} />
              </div>
          </Modal>
      );
  }

  renderPlacholder() {
      const text: string = "Scores Will Be Posted Throughout the Season";
      return (
          <Placeholder icon={"list_alt"} text={text}/>
      );
  }

  render() {
      return (
          <div style={{ flex: 1 }}>
              {this.renderPlacholder()}
          </div>
      );
  }
}

export default connect(mapStateToProps)(Scores);