//@flow
import { LOAD_BOX_SCORE } from '../Actions/BoxScoreActions';

const InitialState: Object = {
    data: {}
};

export default function media(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case LOAD_BOX_SCORE:
        // reduce box scores into view consumable way
        const players: Array<Object> = Object.values(action.json);
        let new_state = { ...state, data: players };
        return new_state;
    default:
        return state;
    }
}
