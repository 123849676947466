//@flow
import React, { Component } from "react";
import "./App.css";
import PageRoutes from "../Config/PageRoutes";
import Tabs from '../Lib/Tabs/Tabs';
import SocialFooter from '../Components/Utility/SocialFooter';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Theme from "../Config/Theme";
import { connect } from 'react-redux';
import { updateWindowState, type WindowDimensionsType } from '../Actions/WindowActions';
import { addWindowStateListener, removeWindowStateListener } from '../Modules/WindowStateListener';
import { BrowserRouter as Router, Route } from "react-router-dom";

type Props = {
  dispatch: any => Object
}
type State = {
  selectedIndex: number,
  drawerOpen: boolean,
  isMobile: boolean,
}

const LIST_ITEM_WIDTH: number = 140.0;

const mapStateToProps = (state: Object) => {
    return {
        isMobile: state.window.isMobile,
    };
};

class App extends Component<Props, State> {

  selectRoute: (route: Object) => void
  onTabSelect: (tab: Object) => void
  toggleDrawer: () => void
  loadImages: () => void
  handleWindowChange: () => void
  isMobile: () => boolean

  constructor(props: Object) {
      super(props);
      this.onTabSelect = this.onTabSelect.bind(this);
      this.toggleDrawer = this.toggleDrawer.bind(this);
      this.loadImages = this.loadImages.bind(this);
      this.handleWindowChange = this.handleWindowChange.bind(this);
      this.isMobile = this.isMobile.bind(this);
      this.state = {
          selectedIndex: 0,
          drawerOpen: false,
          isMobile: true
      };
  }

  isMobile() {
      return this.props.isMobile;
  }


  componentDidMount() {
      addWindowStateListener(this.handleWindowChange);
      this.props.dispatch(updateWindowState({ width: window.innerWidth, height: window.innerHeight }));
  }

  componentWillUnmount() {
      removeWindowStateListener(this.handleWindowChange);
  }

  handleWindowChange(windowDimensions: WindowDimensionsType) {
      this.props.dispatch(updateWindowState(windowDimensions));
  }

  loadImages() {
      const storageRef: Object = Storage.ref().child("Brooks-Rec-League/featured");
      storageRef.listAll()
          .then((res) => {
              const downloadUrls: Array<Promise<string>> = res.items.map((item) => {
                  return item.getDownloadURL();
              });
              Promise.all(downloadUrls)
                  .then((imageSources) => {
                      this.props.dispatch(this.loadGallery(imageSources));
                  });
          });
  }

  onTabSelect(tab: Object) {
      this.setState({
          selectedIndex: tab.index
      });
  }

  renderMenuButton() {
      return (
          <IconButton onClick={this.toggleDrawer} style={{ alignSelf: "flex-start" }}>
              <MenuIcon />
          </IconButton>
      );
  }

  renderCloseButton() {
      return (
          <IconButton onClick={this.toggleDrawer} style={{ alignSelf: "flex-start" }}>
              <CloseIcon />
          </IconButton>
      );
  }

  toggleDrawer() {
      this.setState({
          drawerOpen: !this.state.drawerOpen
      });
  }


  renderWeb() {
      return (
          <div style={{ display: "flex", flexDirection: "column", flex: 1, alignSelf: "flex-grow", alignContent: "flex-grow", backgroundColor: Theme.primary}} className="App">
              <Tabs siteLogo={'./assets/Brooks-Rec-League/brooks_logo.png'} rightLabel={""} hoverColor={Theme.royalBlue} color={"grey"} tabs={Object.values(PageRoutes)} onTabSelect={this.onTabSelect} />
              {this.renderRoutes()}
              <SocialFooter />
          </div>
      );
  }

  renderRoutes() {
      return (
          <Router>
              <div style={{paddingTop: this.props.isMobile ? 50 : 130, textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                  {Object.values(PageRoutes).map(page => {
                      return (
                          <Route path={page.path} exact component={page.component} key={page.key} />
                      );
                  })}
              </div>
          </Router>
      );
  }

  renderMobile() {
      const sideList = side => (
          <div
              className={"drawer_list"}
              role="presentation"
              onClick={this.toggleDrawer}
              onKeyDown={this.toggleDrawer}
              style={{ padding: 0, }}
          >
              <List style={{ padding: 0 }}>
                  <ListItem style={{ height: 49, borderBottom: "1px solid black" }}>
                      <CloseIcon />
                  </ListItem>
                  {Object.values(PageRoutes).map((route) => {
                      const backgroundColor: string = window.location.pathname === route.path ? Theme.royalBlue : Theme.primary;
                      return (
                          <ListItem style={{ backgroundColor: backgroundColor, borderBottom: "1px solid white", width: LIST_ITEM_WIDTH }} onClick={() => {
                              window.location.href=route.path;
                          }} button key={route.key}>
                              <ListItemText style={{ color: "white", backgroundColor: backgroundColor, fontFamily: "IBM Plex Sans", fontWeight: "800" }} primary={route.label.toLocaleUpperCase()} />
                          </ListItem>
                      );
                  })}
              </List>
              <Divider />
          </div>
      );
      return (
          <div style={{ display: "flex", flexDirection: "column", flex: 1, alignSelf: "flex-grow", alignContent: "flex-grow", backgroundColor: Theme.primary }} className="App">
              <div style={{ position: "fixed", top: 0, left: 0, right: 0, backgroundColor: "white", zIndex: 1000, borderBottom: "0.5px solid grey", display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  {this.renderMenuButton()}
                  {this.renderSiteLabel()}
              </div>
              <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer}>
                  {sideList('left')}
              </Drawer>
              <div style={{ padding: 20, backgroundColor: Theme.primary }}>
                  {this.renderRoutes()}
              </div>
              {this.renderSocialFooter()}
          </div>
      );
  }

  renderSocialFooter() {
      if (this.state.drawerOpen) {
          return null;
      } else {
          return <SocialFooter />;
      }
  }

  renderSiteLabel() {
      return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
              <img style={{ height: 40, marginRight: 10 }} alt="media" src={"assets/Brooks-Rec-League/brooks_logo.png"} />
          </div>
      );
  }

  render() {
      return this.props.isMobile ? this.renderMobile() : this.renderWeb();
  }
}

export default connect(mapStateToProps)(App);