//@flow
import { LOAD_SCORES } from '../Actions/ScoreActions';
import { ScoresData } from '../Types/ScoresData';

const InitialState: Object = {
    data: new ScoresData({}),
};

export default function media(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case LOAD_SCORES:
        return { ...state, data: new ScoresData(action.json) };
    default:
        return state;
    }
}
