//@flow
import React, { Component } from 'react';
import Tab from './Tab';
import { isMobile } from "react-device-detect";
import './Tab.css';

type TabModel = {
  index: number,
  label: string,
}

export type TabProps = {
  hoverColor: string,
  color: string,
  backgroundColor: string,
  containerStyle: ?Object,
  tabs: Array<Object>,
  onTabSelect: TabModel => void,
  rightLabel: string,
  siteLogo: string,
}

type State = {
  index: number
}

const DefaultStyle: Object = {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    width: "100%",
    color: "grey",
    backgroundColor: "white",
    position: "fixed",
    top: 0,
    zIndex: 100,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid grey",
};

export default class Tabs extends Component<TabProps, State> {

    constructor(props: Object) {
        super(props);
        this.state = {
            index: props.selected || 0,
        };
    }

    onTabSelect(tab: TabModel) {
        this.setState({
            index: tab.index
        });
        if (this.props.onTabSelect) {
            this.props.onTabSelect(tab);
        }
        window.scrollTo(0, 0);
    }

    renderSiteLabel() {
        if (!this.props.siteLogo || isMobile) {
            return <div style={{ flex: 1 }} />;
        }
        return (
            <h3 style={{ fontSize: 20, margin: 10, width: 150, fontWeight: "800", flex: 1, textAlign: "right" }}>{this.props.rightLabel}</h3>
        );
    }

    renderSiteLogo() {
        return (
            <img objectFit={"contain"} alt={""} src={this.props.siteLogo} style={{ height: 70, }} />
        );
    }

    render() {
        return (
            <div className={"Tabs"} style={this.props.containerStyle || DefaultStyle}>
                {this.props.tabs.map((tab, index) => {
                    return (
                        <Tab key={index} backgroundColor={this.props.backgroundColor} onClick={() => {
                            window.location.pathname=tab.path;
                        }} color={(window.location.pathname === tab.path) ? this.props.hoverColor : this.props.color} hoverColor={this.props.hoverColor} index={index} label={tab.label} />
                    );
                })}
                {this.renderSiteLabel()}
                {this.renderSiteLogo()}
            </div>
        );
    }
}