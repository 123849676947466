//@flow
export const Colors = {
  orange: "#a5caeb",
  orange_accent: "#a5caeb",
  dark_grey: "#282828",
  navy: "#3E4C55",
  royalBlue: "#276D9E",
  black: "black",
  white: "white",
  lightGray: "#e0e0e0",
  babyBlue: "#a5caeb"
};

export default {
  primary: Colors.dark_grey,
  secondary: Colors.white,
  light: Colors.lightGray,
  orange: Colors.orange,
  primaryText: Colors.lightGray,
  secondaryText: Colors.orange_accent,
  navy: Colors.navy,
  royalBlue: Colors.royalBlue,
  babyBlue: Colors.babyBlue
};