//@flow
import About from '../Components/Pages/About'
import Contact from '../Components/Pages/Contact'
import Media from '../Components/Pages/Media'
import Standings from '../Components/Pages/Standings'
import Registration from '../Components/Pages/Registration'
import Schedule from '../Components/Pages/Schedule'
import Sponsors from '../Components/Pages/Sponsors'
import Scores from '../Components/Pages/Scores'

export type Route = {
  index: number,
  key: string,
  label: string,
  render: () => Object
}

export default {
  Page1: {
    index: 0,
    key: "about",
    label: "Home",
    path: "/",
    component: About,
  },
  Page2: {
    index: 1,
    key: "media",
    label: "Media",
    path: "/media",
    component: Media
  },
  Page3: {
    index: 2,
    key: "registration",
    label: "Registration",
    path: "/registration",
    component: Registration
  },
  Page4: {
    index: 3,
    key: "standings",
    label: "Standings",
    path: "/standings",
    component: Standings
  },
  Page5: {
    index: 4,
    key: "schedule",
    label: "Schedule",
    path: "/schedule",
    component: Schedule
  },
  Page6: {
    index: 5,
    key: "scores",
    label: "Scores",
    path: "/scores",
    component: Scores
  },
  Page7: {
    index: 6,
    key: "sponsors",
    label: "Sponsors",
    path: "/sponsors",
    component: Sponsors
  },
  Page8: {
    index: 7,
    key: "contact",
    label: "Contact",
    path: "/contact",
    component: Contact
  },
}