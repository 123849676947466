//@flow
import media from './MediaReducer';
import window from './WindowReducer';
import scores from './ScoresReducer';
import box_score from './BoxScoreReducer';
import announcements from './AnnoucementReducer';
import sponsors from './SponsorReducer';
import { combineReducers } from 'redux';

export default combineReducers({
    media,
    window,
    scores,
    box_score,
    announcements,
    sponsors
});