//@flow
import { LOAD_ANOUNCEMENTS } from '../Actions/AnnouncementActions';

const InitialState: Object = {
    data: []
};

export default function media(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case LOAD_ANOUNCEMENTS:
        let new_state = { ...state, data: Object.values(action.json) };
        return new_state;
    default:
        return state;
    }
}
