//@flow
import React, { Component } from 'react';
import Game from '../../Types/Game';
import Schedule from '../../Types/Schedule';
import { type DaySchedule } from '../../Types/Schedule';
import moment from 'moment';
import Placeholder from '../../Lib/Placeholder';
//import ScheduleJSON from '../../Data/Schedule.json'

type Props = {
  isMobile: boolean
}
type State = {
  schedule: Schedule,
}

class ScheduleView extends Component<Props, State> {

  renderGame: () => Object

  /*
  constructor(props: Object) {
    super(props)
    this.state = {
      schedule: new Schedule(ScheduleJSON)
    }
  }
  */

  render() {
      return (
          <div>
              {this.renderPlacholder()}
          </div>
      );
  }

  renderPlacholder() {
      const text: string = "The 2019 Schedule Will Be Announced Soon";
      return (
          <Placeholder icon={"calendar_today"} text={text}/>
      );
  }

  renderSchedule() {
      const schedule: Schedule = this.state.schedule;
      return (
          <div>
              {
                  schedule.data.map((daySchedule, index) => {
                      return this.renderSingleDaySchedule(daySchedule, index);
                  })
              }
          </div>
      );
  }

  renderSingleDaySchedule(daySchedule: DaySchedule, index: number) {
      var dateString: string = new moment(daySchedule.date).format("dddd MMMM - Do");
      dateString = dateString.toLocaleUpperCase();
      return (
          <div key={index} style={{ justifyContent: this.props.isMobile ? "center" : "flex-start" }}>
              <div style={{ backgroundColor: "#303030", paddingTop: 5, paddingBottom: 5 }}>
                  <h3 style={{ fontSize: this.props.isMobile ? 12.0 : 14.0, textAlign: this.props.isMobile ? "center" : "left", marginLeft: 20, color: "white", marginTop: 5 }}>{dateString}</h3>
              </div>
              {daySchedule.games.map((game, index) => {
                  return this.renderGame(game, index);
              })}
          </div>
      );
  }

  renderGame(game: Game, index: number) {
      const displayString: string = game.team_1.team_name + " vs " + game.team_2.team_name;
      const dateString: string = moment(game.date).format("h:mm A");
      if (this.props.isMobile) {
          return (
              <div key={index} style={{ display: "flex", alignItems: "center", flexDirection: "row", flex: 1, fontWeight: "800", paddingTop: 15, paddingBottom: 10, backgroundColor: "#f0f0f0" }}>
                  <h3 style={{ width: 100, fontSize: 12, fontWeight: "800", marginRight: 15 }}>{dateString}</h3>
                  <h3 style={{ fontSize: 12, textAlign: "left" }}>{displayString}</h3>
              </div>
          );
      } else {
          return (
              <div key={index} style={{ display: "flex", alignItems: "center", flexDirection: "row", flex: 1, fontWeight: "800", paddingBottom: 7.5, paddingLeft: 15, backgroundColor: "#f0f0f0" }}>
                  <h3 style={{ fontSize: this.props.isMobile ? 12 : 14, width: 80, fontWeight: "800", marginRight: 10, marginTop: 15, }}>{dateString}</h3>
                  <h3 style={{ fontSize: this.props.isMobile ? 12 : 14, textAlign: "left", marginTop: 15 }}>{displayString}</h3>
              </div>
          );
      }

  }
}

export default ScheduleView;