//@flow
import React, { Component } from 'react';
import './Tab.css';

type TabState = {
  hovering: boolean
}

export type TabProps = {
  hoverColor: string,
  color: string,
  backgroundColor: string,
  label: string,
  onClick: Object => void
}

export default class Tab extends Component<TabProps, TabState> {

  onMouseOver: () => void
  onMouseLeave: () => void
  onClick: () => void

  constructor(props: Object) {
      super(props);
      this.onMouseOver = this.onMouseOver.bind(this);
      this.onMouseLeave = this.onMouseLeave.bind(this);
      this.onClick = this.onClick.bind(this);
      this.state = {
          hovering: false
      };
  }

  onClick() {
      if (this.props.onClick) {
          this.props.onClick();
      }
  }

  onMouseOver() {
      this.setState({
          hovering: true
      });
  }

  onMouseLeave() {
      this.setState({
          hovering: false,
      });
  }

  renderOnHover() {
      return (
          <div><h3 style={{ color: this.props.hoverColor, margin: 20, cursor: "pointer", fontWeight: "800" }}>{this.props.label.toUpperCase()}</h3></div>
      );
  }

  renderDefault() {
      return (
          <div><h3 style={{ color: this.props.color, margin: 20, cursor: "pointer", fontWeight: "800" }}>{this.props.label.toUpperCase()}</h3></div>
      );
  }

  render() {
      return (
          <div className={"Tab"} onClick={this.onClick} onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
              {this.state.hovering ? this.renderOnHover() : this.renderDefault()}
          </div>
      );
  }

}