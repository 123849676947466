//@flow
import { LOAD_SPONSORS } from '../Actions/SponsorActions';

const InitialState: Object = {
    data: {}
};

export default function media(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case LOAD_SPONSORS:
        return { ...state, data: action.json };
    default:
        return state;
    }
}
