//@flow
import Team from '../Types/Team';

export type Division = {
  name: string,
  key: string,
  teams: Array<Team>
}

export type StandingsDataModel = {
  divisions: Array<Division>
}

export const MOCK_STANDINGS: StandingsDataModel = {
    divisions: [{
        name: "LAY-UP DIVISION",
        key: "LAY-UP DIVISION",
        teams: [new Team({
            team_name: "Redemption",
            wins: 7,
            losses: 3,
            key: "Redemption",
            plus_minus: "+104",
            dvision_record: "5-1"
        }),
        new Team({
            team_name: "86ers",
            wins: 6,
            losses: 4,
            plus_minus: "+46",
            dvision_record: "3-3"
        }),
        new Team({
            team_name: "Paid In Full",
            wins: 5,
            losses: 5,
            plus_minus: "-6",
            dvision_record: "3-3"
        }),
        new Team({
            team_name: "Chemistry",
            wins: 3,
            losses: 7,
            plus_minus: "-62",
            dvision_record: "1-5"
        })]
    },
    {
        name: "FADEAWAY DIVISION",
        key: "FADEAWAY DIVISION",
        teams: [new Team({
            team_name: "RE24CT",
            wins: 8,
            losses: 2,
            plus_minus: "+163",
            dvision_record: "5-1"
        }),
        new Team({
            team_name: "Flight Classic",
            wins: 8,
            losses: 2,
            plus_minus: "+177",
            dvision_record: "4-2"
        }),
        new Team({
            team_name: "Action Unlimited",
            wins: 2,
            losses: 8,
            plus_minus: "-110",
            dvision_record: "2-4"
        }),
        new Team({
            team_name: "Run In Up!",
            wins: 1,
            losses: 9,
            plus_minus: "-314",
            dvision_record: "1-5"
        })
        ]
    }
    ]
};