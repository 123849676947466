//@flow
import React, { Component } from 'react';
import { FaFacebook, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import Theme from '../../Config/Theme';

type Props = {
  height: ?number
}

type State = {
  bottom: number,
}

export const FOOTER_HEIGHT: number = 50.0;
export default class SocialFooter extends Component<Props, State> {

  resize: () => void
  footerRef: Object

  constructor(props: Object) {
      super(props);
      this.resize = this.resize.bind(this);
      this.footerRef = React.createRef();
      this.state = {
          bottom: 0
      };
  }

  resize() {
      this.setState({
          bottom: 0.0
      });
  }

  componentDidMount() {
      window.addEventListener('resize', this.resize);
      window.addEventListener('onscroll', this.resize);
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.resize);
      window.addEventListener('onscroll', this.resize);
  }

  render() {
      return (
          <div ref={this.footerRef} style={{ position: "fixed", bottom: this.state.bottom, height: FOOTER_HEIGHT, width: "100%", borderTop: "1px solid black", backgroundColor: Theme.secondary, display: "flex", alignItems: "center", justifyContent: "space-between", padding: 20 }}>
              <div>
                  {this.renderFacebook()}
              </div>
              {this.renderRRTag()}
          </div>
      );
  }

  renderRRTag() {
      return (
          <img src={'assets/Branding/RR_Long_Black.png'} style={{ height: 30, marginRight: 10 }} alt={''}/>
      );
  }

  renderInstagram() {
      return ( 
          <a href="https://www.instagram.com/BrooksRec/" alt={''}><FaInstagram style={{ color: Theme.primary, fontSize: 24, marginRight: 10 }} /></a>
      );
  }

  renderFacebook() {
      return (
          <a href="https://www.facebook.com/groups/1646813702252495/?ref=bookmarks" alt={''}> <FaFacebook style={{ color: Theme.primary, fontSize: 24, marginRight: 10 }} /></a>
      );
  }

  renderTwitter() {
      return (
          <a href="https://twitter.com/BrooksRec"><FaTwitterSquare style={{ color: "white", fontSize: 24 }} /></a >
      );
  }

}