//@flow
export type WindowStateType = {
  height: number,
  width: number,
  isMobile: boolean
}

export type WindowDimensionsType = {
  height: number,
  width: number
}

export const UPDATE_WINDOW_STATE = "UPDATE_WINDOW_STATE"
export const SHOW_BOX_SCORE_MODAL = "SHOW_BOX_SCORE_MODAL"


export function updateWindowState(state: WindowDimensionsType) {
  return { type: UPDATE_WINDOW_STATE, state }
}

export function setBoxScoreModalVisibility(visible: boolean) {
  return { type: SHOW_BOX_SCORE_MODAL, visible }
}
