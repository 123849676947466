//@flow
import { LOAD_GALLERY } from '../Actions/MediaActions';

const InitialState: Object = {
    images: []
};

export default function media(state: Object = InitialState, action: Object) {
    switch (action.type) {
    case LOAD_GALLERY:
        return { images: action.images };
    default:
        return state;
    }
}
