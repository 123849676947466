//@flow
import React, { Component } from 'react';
import { Modal, Icon } from "antd";
import Image from './Image';
import Theme from '../Config/Theme';

type Props = {
  images: Array<string>,
  mobileImagesPerRow: number,
  webImagesPerRow: number,
  imagePadding: number,
  marginBottom: Number,
  galleryWidth: number,
  enablePreview: boolean,
  greyscaleEnabled: ?boolean,
  greyscaleOpacity: ?number,
  isMobile: Boolean,
}

type State = {
  imageData: Object,
  previewVisible: boolean,
  previewImage: string
}

export default class DynamicGallery extends Component<Props, State> {

  calibrateImageSize: () => void

  constructor(props: Object) {
      super(props);
      this.calibrateImageSize = this.calibrateImageSize.bind(this);
      this.state = {
          imageData: {},
          previewVisible: false,
          previewImage: "",
          height: 500,
          width: 500,
      };
  }

  componentDidMount() {
      window.addEventListener("resize", this.calibrateImageSize);
      this.calibrateImageSize();
  }

  renderPreview() {
      return (
          <Modal
              visible={this.state.previewVisible}
              onOk={this.handleDismiss}
              onCancel={() => {
                  this.setState({
                      previewVisible: false
                  });
              }}
              footer={null}
          >
              <img alt={"media"} style={{ width: "100%", height: "100%", padding: 20, }} src={this.state.previewImage} />
          </Modal>
      );
  }

  renderImage(uri: string, index: number) {
      return (
          <Image onClick={() => {
              this.setState({
                  previewImage: uri,
                  previewVisible: true
              });
          }} height={this.state.height} width={this.state.width} src={uri} alt={"media"}/>
      );
  }


  render() {
      return (
          <div style={{display: "flex", flexWrap: "wrap", width: this.props.width, backgroundColor: Theme.primary, alignItems: "center", justifyContent: "center"}}>
              {Object.values(this.props.images).map((images, index) =>
                  this.renderImage(images, index)
              )}
              {this.renderPreview()}
          </div>
      );
  }

  renderSocialIcon(type: string, handler: () => void) {
      return (
          <Icon style={{ marginRight: 5, fontSize: 30, color: "black" }} type={type} color={"black"} />
      );
  }

  calibrateImageSize() {
      var n = 4;
      const screenWidth: number = window.innerWidth;
      if(screenWidth > 1300) {
          n = 4;
      } else if(screenWidth > 1000) {
          n = 3;
      } else if(screenWidth > 700) {
          n = 2;
      } else if(screenWidth > 350) {
          n = 1;
      }
      const dimension: number = screenWidth/ n;
      this.setState({
          width: dimension,
          height: dimension,
      });
  }
}
