//@flow
import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gallery from '../../Lib/Gallery';
import { connect } from 'react-redux';
import Theme from '../../Config/Theme';
import { Storage } from '../../Config/Firebase';
import { loadGallery } from "../../Actions/MediaActions";

type Props = {}
type State = {
    showModal: boolean,
    selectedPhoto: string
}

const mapStateToProps = (state: Object) => {
    return {
        images: state.media.images,
        isMobile: state.window.isMobile,
        width: state.window.screen_width,
        tab_height: state.window.tab_height,
    };
};

class Media extends Component<Props, State> {

    showPreview: (photo: string) => void
    hidePreview: () => void

    constructor(props: Object) {
        super(props);
        this.showPreview = this.showPreview.bind(this);
        this.hidePreview = this.hidePreview.bind(this);
        this.state = {
            images: []
        };
    }

    componentDidMount() {
        this.loadImages();
    }

    showPreview(photo: string) {
        this.setState({
            showModal: true,
            selectedPhoto: photo
        });
    }

    hidePreview() {
        this.setState({
            showModal: false,
            selectedPhoto: null
        });
    }

    loadImages() {
        const storageRef: Object = Storage.ref().child("Brooks-Rec-League/featured");
        storageRef.listAll()
            .then((res) => {
                const downloadUrls: Array<Promise<string>> = res.items.map((item) => {
                    return item.getDownloadURL();
                });
                Promise.all(downloadUrls)
                    .then((imageSources) => {
                        this.props.dispatch(loadGallery(imageSources));
                    });
            });
    }

    renderGallery() {
        return (
            <Gallery width={this.props.width} isMobile={this.props.isMobile} marginBottom={this.props.isMobile ? 0.0 : 20.0} mobileImagesPerRow={1} webImagesPerRow={3} greyscaleEnabled={false} greyscaleOpacity={0.7} imagePadding={20.0} enablePreview={true} style={{ width: "100%", height: "100%", backgroundColor: Theme.primary }} images={this.props.images} />   
        );
    }

    render() {
        return (
            <div style={{ height: window.innerHeight, marginTop: -40.00, width: window.innerWidth, display: "flex", backgroundColor: Theme.primary,}}>
                {this.renderGallery()}
            </div>
        );
    }
}

export default connect(mapStateToProps)(Media);